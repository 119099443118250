import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

function Location() {
  return (
    <Container style={{ padding: '20px 20px' }}>
      <Row>
        <Col>
          <Card style={{ marginBottom: '20px', border: 'none',}}>
            <Card.Body>
                <h2 className="mb-4 text-center">Our Location</h2>   
              <Card.Text>
                <strong>Address:</strong> Nad Dalejským údolím 2699/9, 155 00 Praha 13, Czechia
              </Card.Text>
              <Card.Text>
                <strong>Opening Hours:</strong> <br />
                Mon - Fri: 8:00 AM - 5:00 PM <br />
                Sun - Sat: 9:00 AM - 5:00 PM
              </Card.Text>
              <Card.Text>
                <strong>Contact:</strong> 777 758 895
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
    </Row>
    <Row>
        <Col>
          <div style={{ position: 'relative', overflow: 'hidden', width: '100%', paddingTop: '56.25%' }}>
            <iframe
              title="Google Map"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Nad%20Dalejsk%C3%BDm%20%C3%BAdol%C3%ADm%202699/9,%20155%2000%20Praha%2013,%20Czechia+(Facetherapy)&amp;t=h&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              width="100%"
              height="100%"
              style={{ border: 0, position: 'absolute', top: 0, left: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Location;
