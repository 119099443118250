import React from 'react';
import { Container, Card, CardBody } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; 


function PhotoElement() {
    const styles = {
        pageContainer: {
          paddingTop: '60px',
          paddingBottom: '60px',
        },
        container: {
          margin: '0 auto',
          alignitems: 'center',
        },
        card: {
          padding: '40px',
          borderRadius: '20px',
          backgroundColor: '#ffffff',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          border: 'none',
        }
    };

    return (
        <div style={styles.pageContainer}>
            <Container className="justify-content-center" style={styles.container}>
                <Card style={styles.card}></Card>
                    <CardBody className="align-items-center">
                        <Card.Img variant="top" src="../photos/photo10.jpg" />
                    </CardBody>
                <Card/>
                <Card style={styles.card}></Card>
                    <CardBody className="align-items-center">
                        <Card.Img variant="top" src="../photos/photo11.jpg" />
                    </CardBody>
                <Card/>
            </Container>
        </div>
    );
};

export default PhotoElement;