import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import MapElement from "../vendor/mapElement";
import ContactForm from "./contact";
import ContactDetail from "../details/contactDetail";
import Location from "./location";

function ContactUs() {
    return (
        <>  
            <ContactDetail />
            <Container style={{ paddingTop: '60px' }}>
                <Row>
                    <Col xs={12} md={6}>
                    <Card style={{ paddingTop: '40px', border: 'none',  }}>
                        <h2 className="mb-4 text-center">Contact Us</h2>
                        <ContactForm />
                    </Card>
                    </Col>
                    <Col xs={12} md={6}> 
                        <Location />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ContactUs;