import React from 'react';
import { Container } from 'react-bootstrap';

  function HomePageElement() {
    return (
      <div
        style={{
          position: 'relative',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: '150px 20px',
          color: '#fff',
          textAlign: 'center',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
        ></div>
        <Container style={{ zIndex: 2, position: 'relative' }}>
          <h1 className="display-4 font-weight-bold">Welcome to FaceTherapy</h1>
          <p className="lead">Discover the art of restoring natural beauty without injections or surgery.</p>
        </Container>
      </div>
    );
  }

  export default HomePageElement;