import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Accordion, Container, Row, Col, Card } from 'react-bootstrap';
import ServicesDetail from '../details/servicesDetail';
import PhotoCarousel from './photoCarousel';

function Services() {
  const services = [
    {
      title: "Facial Massage 1 Hour",
      description: "Experience a range of techniques including stimulating, lifting, and relaxing massages to rejuvenate your skin.",
      price: "1,250.00 Kč"
    },
    {
      title: "Modeling Sculptural SMAS Facial Massage 1.5 Hours",
      description: "A specialized facial massage targeting the SMAS layer for enhanced contouring and lifting effects.",
      price: "1,750.00 Kč"
    },
    {
      title: "Chemical Peeling",
      description: "Revitalize your skin with a chemical peel tailored to your skin type and concerns.",
      price: "from 1,000.00 Kč"
    },
    {
      title: "Hardware Rejuvenation",
      description: "Utilize advanced technologies like RF, OXY-jet, Ultrasound Therapy, and Cryotherapy for comprehensive skin rejuvenation.",
      price: "from 1,250.00 Kč"
    },
    {
      title: "Hardware Rejuvenation + Chemical Peeling",
      description: "Combine the benefits of hardware rejuvenation with a chemical peel for maximum skin revitalization.",
      price: "from 1,500.00 Kč"
    },
    {
      title: "Super Cleaning",
      description: "Achieve deep skin cleansing with a combination of hardware treatments and peeling for refreshed, clear skin.",
      price: "from 1,250.00 Kč"
    },
    {
      title: "Individual Consultation",
      description: "Get personalized skincare advice and treatment recommendations tailored to your unique needs.",
      price: "2,500.00 Kč"
    },
  ];

  return (
    <>
    <Container fluid className="p-0 m-0">
      <ServicesDetail />
      <Container style={{ paddingTop: '60px' }}>
        <h2 className="mb-4 text-center">Our Services</h2>
        <Accordion defaultActiveKey="0">
          {services.map((service, index) => (
            <Accordion.Item eventKey={index.toString()} key={index}>
              <Accordion.Header>
                {service.title} - <strong>{service.price}</strong>
              </Accordion.Header>
              <Accordion.Body>
                <p>{service.description}</p>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Container>
      <Container style={{ paddingTop: '60px' }}>
        <Row>
          <Col>
            <h2 className="mb-4 text-center">Our Work</h2>
            <PhotoCarousel />
          </Col>
        </Row>
      </Container>
    </Container>
    </>
  );
}

export default Services;