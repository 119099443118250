import React, { useState } from 'react';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const sanitizeInput = (input) => {
    const div = document.createElement('div');
    div.innerText = input;
    return div.innerHTML;
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [formErrors, setFormErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateForm = () => {
    let errors = {};

    // Sanitize the inputs before validation To incorporate Cross-Site Scripting (XSS)
    const sanitizedData = {
      name: sanitizeInput(formData.name),
      email: sanitizeInput(formData.email),
      phone: sanitizeInput(formData.phone),
      message: sanitizeInput(formData.message),
    };

    if (!sanitizedData.name) errors.name = 'Name is required';
    if (!sanitizedData.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(sanitizedData.email)) {
      errors.email = 'Email is invalid';
    }
    if (!sanitizedData.phone) {
      errors.phone = 'Phone number is required';
    } else if (!/^\+?\d{7,15}$/.test(sanitizedData.phone)) {
      errors.phone = 'Phone number is invalid';
    }
    if (!sanitizedData.message) errors.message = 'Message is required';

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      emailjs.send(
        'service_bf3wx5f',       
        'template_pwmadeh',      
        formData,                // Pass the form data
        'TEMB3ZCsC1EyG9mrC'           
      )
      .then((result) => {
        console.log('Email sent successfully:', result.text);
        setSubmitted(true);
        setFormData({ name: '', email: '', phone: '', message: '' }); // Clear form
      })
      .catch((error) => {
        console.error('Error sending email:', error.text);
      });
    } else {
      setFormErrors(errors);
    }
  };

  return (
    
    <Container style={{ maxWidth: '600px', paddingTop: '40px' }}>
      {submitted && <Alert variant="success">Thank you! Your message has been sent.</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName" className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            isInvalid={!!formErrors.name}
            required
          />
          <Form.Control.Feedback type="invalid">{formErrors.name}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formEmail" className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            isInvalid={!!formErrors.email}
            required
          />
          <Form.Control.Feedback type="invalid">{formErrors.email}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formPhone" className="mb-3">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            isInvalid={!!formErrors.phone}
            required
          />
          <Form.Control.Feedback type="invalid">{formErrors.phone}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formMessage" className="mb-3">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            name="message"
            rows={4}
            value={formData.message}
            onChange={handleInputChange}
            isInvalid={!!formErrors.message}
            required
          />
          <Form.Control.Feedback type="invalid">{formErrors.message}</Form.Control.Feedback>
        </Form.Group>
        <Container className="text-center">
          <Button variant="outline-secondary" type="submit">
          Submit
        </Button>
        </Container>
        
      </Form>
    </Container>
  );
};

export default ContactForm;
