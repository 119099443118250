import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // router for app
import MyNav from './elements/header';
import Footer from './elements/footer';
import HomePage from './elements/home';
import About from './elements/AboutMain'; 
import AboutWeb from './elements/yourstyle';
import Services from './elements/service';
import ContactUs from './elements/contactUs';

import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <Router>
      <div>
        <MyNav />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/aboutme" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/your-style" element={<AboutWeb />} />
          <Route path="/contactus" element={<ContactUs />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;


