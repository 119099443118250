import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
  return (
    <footer className="bg-dark text-white mt-5 p-4 text-center">
      <Container>
        <Row>
          <Col md="4">
            <h5>Working hours</h5>
            <p>
              Mon - Fri: 9:00 - 15:00 <br />
              Sat - Sun : closed <br />
            </p>
          </Col>
          <Col md="4">
            <h5>Find us there: </h5>
            <a href="https://taplink.cc/facetherapy.cz" className="text-white me-2">
              <i className="fab fa-taplink"></i> Taplink
            </a>
            <br />
            <a href="https://www.instagram.com/facetherapy.cz/" className="text-white me-2">
              <i className="fab fa-instagram"></i> Instagram
            </a>
          </Col>
          <Col md="4">
            <h5>Contact Us</h5>
            <p>
              Email: facetherapycz@gmail.com <br />
              Phone: +420 777 758 895
            </p>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <p className="mt-3">
              &copy; {new Date().getFullYear()} facetherapy. All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
