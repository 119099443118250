import React, { useState } from 'react';
import { Container, Card, Row, Col, Carousel, Modal, Button  } from 'react-bootstrap';
import HomePageElement from './heroElement.js';
import ContactForm from './contact.js';
import HomeDetail from '../details/homeDetail.js';
import Location from './location.js';
import 'bootstrap/dist/css/bootstrap.min.css';

function HomePage() {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', text: '', imgSrc: '' });

  const handleCardClick = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const services = [
    {
      title: 'Youthful Facial Sculpting',
      text: 'Enhance and define your facial features with targeted exercises designed to lift and firm, giving you a naturally youthful look.',
      imgSrc: '../photos/photo9.jpg',
    },
    {
      title: 'Advanced Skincare Solutions',
      text: 'Address aging signs like wrinkles, pigmentation, and sagging with our customized skincare treatments that restore and rejuvenate.',
      imgSrc: '../photos/photo1.jpg',
    },
    {
      title: 'Therapeutic Facial Massage',
      text: 'Experience deep relaxation and improved skin vitality with massage techniques that boost circulation and refine facial contours.',
      imgSrc: '../photos/photo4.jpg',
    },
    {
      title: 'Facial Rejuvenation Exercises',
      text: 'Tone and refresh your skin with exercises that target facial muscles, promoting a smooth and lifted appearance.',
      imgSrc: '../photos/photo8.jpg',
    },
    {
      title: 'Customized Cosmetology Care',
      text: 'Tailored skincare solutions to combat aging, from fine lines to uneven skin tone, leaving your complexion radiant and smooth.',
      imgSrc: '../photos/photo5.jpg',
    },
    {
      title: 'Holistic Facial Therapy',
      text: 'Restore your skin’s natural glow and firmness with massage techniques that focus on lifting and rejuvenating tired skin.',
      imgSrc: '../photos/photo6.jpg',
    },
  ];
  return (
    <div>
      <HomeDetail/>
      {/* Introduction Section */}
      <section style={{ 
        padding: '60px 20px', 
        backgroundColor: '#f8f9fa' 
      }}
      >
        <Container>
          <h2 className="mb-4 text-center">About FaceTherapy</h2>
          <p className="lead">
            At FaceTherapy, we specialize in restoring and rejuvenating your face naturally. Whether you're looking to erase signs of aging, correct facial asymmetry, or recover from cosmetic procedures, our certified experts offer personalized care to help you look and feel your best.
          </p>
        </Container>
      </section>

      {/* Services Section */}
      <section id="services" style={{ padding: '60px 20px'}}>
        <Container>
          <h2 className="mb-4 text-center">Our Services</h2>
          <Row className="mb-4">
            {services.map((service, index) => (
              <Col xs={12} md={6} lg={4} key={index}>
                <Card
                  className="mb-4 shadow-sm d-flex flex-column h-100"
                  style={{ transition: 'transform 0.3s', cursor: 'pointer', border: 'none',}}
                  onClick={() => handleCardClick(service)}
                >
                  <Card.Img
                    variant="top"
                    src={service.imgSrc}
                    style={{ height: '200px', objectFit: 'cover' }}
                  />
                  <Card.Body className="flex-grow-1 d-flex flex-column">
                    <Card.Title>{service.title}</Card.Title>
                    <Card.Text>{service.text}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalContent.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={modalContent.imgSrc}
            alt={modalContent.title}
            style={{ width: '100%', marginBottom: '20px' }}
          />
          <p>{modalContent.text}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Testimonials Section */}
      <section style={{ padding: '60px 20px', backgroundColor: '#f8f9fa' }}>
        <Container>
          <h2 className="mb-4 text-center">What Our Clients Say</h2>
          <Carousel interval={5000}>
            <Carousel.Item>
              <Card className="shadow-sm text-center">
                <Card.Body>
                  <Card.Text as="blockquote" className="mb-0">
                    "FaceTherapy has transformed my skin and my confidence. The natural methods truly work wonders!"
                  </Card.Text>
                  <footer className="blockquote-footer mt-2">Sarah, 35</footer>
                </Card.Body>
              </Card>
            </Carousel.Item>
            <Carousel.Item>
              <Card className="shadow-sm text-center">
                <Card.Body>
                  <Card.Text as="blockquote" className="mb-0">
                    "The personalized care and expertise at FaceTherapy have made a noticeable difference in my appearance."
                  </Card.Text>
                  <footer className="blockquote-footer mt-2">Anna, 42</footer>
                </Card.Body>
              </Card>
            </Carousel.Item>
            <Carousel.Item>
              <Card className="shadow-sm text-center">
                <Card.Body>
                  <Card.Text as="blockquote" className="mb-0">
                    "My skin felt young again, impressive specialization and skills."
                  </Card.Text>
                  <footer className="blockquote-footer mt-2">Hana, 48</footer>
                </Card.Body>
              </Card>
            </Carousel.Item>
          </Carousel>
        </Container>
      </section>

      {/* Location section */}
      <section style={{ padding: '60px 20px' }}>
        <Container className="text-center">
          <Location />
        </Container>

      </section>
    </div>
  );
}

export default HomePage;
