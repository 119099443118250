import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; 

const photoGallery = [
    {
        id: 1,
        image: '../photos/photo1.jpg',
    },
    {
        id: 2,
        image: '../photos/photo2.jpg',
    },
    {
        id: 3,
        image: '../photos/photo3.jpg',
    },
    {
        id: 4,
        image: '../photos/photo5.jpg',
    },
    {
      id: 5,
      image: '../photos/photo11.jpg',
    },
    
    {
      id: 6,
      image: '../photos/photo14.jpg',
    },
    
];

const PhotoCarousel = () => {
    return (
      <Carousel>
        {photoGallery.map((photo, index) => (
          <Carousel.Item key={photo.id}>
            <img
              className="d-block w-100 h-100"
              src={photo.image}
              alt={photo.title}
            />
            <Carousel.Caption>
              <h3>{photo.title}</h3>
              <p>{photo.description}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    );
  };

export default PhotoCarousel;