import React from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import AboutDetail from '../details/aboutDetail';
import 'bootstrap/dist/css/bootstrap.min.css';
import Location from './location';

function AboutWeb() {
  return (
    <><AboutDetail /><Container className="my-5">

      <Card className="shadow-sm border-0" style={{ padding: '20px', borderRadius: '10px' }}>
        <Card.Body>
          <Card.Title as="h1" className="mb-4">About Us</Card.Title>

          <Card.Text>
            Welcome to Your Style salon, where we offer a range of cosmetic procedures that utilize both advanced instrumental techniques
            and traditional manual methods. Our salon is dedicated to providing you with personalized beauty treatments
            that enhance your natural beauty and promote skin health. Whether you're looking for a sophisticated technology-assisted facial or a classic hands-on massage, our expert technicians are equipped to meet your needs.
          </Card.Text>

          <Row className="my-4">
            <Col md={4}>
              <img src="./photos/photo1.jpg" alt="Advanced Techniques" className="img-fluid rounded" />
            </Col>
            <Col md={4}>
              <img src="./photos/photo2.jpg" alt="Traditional Methods" className="img-fluid rounded" />
            </Col>
            <Col md={4}>
              <img src="./photos/photo3.jpg" alt="Personalized Consultations" className="img-fluid rounded" />
            </Col>
          </Row>

          <Card.Text>
            Understanding that each individual's skin is unique, we offer personalized consultations to determine the most effective
            techniques and products for your specific skin concerns. During these consultations, our specialists assess your skin
            type and conditions to recommend the most beneficial treatments. This tailored approach ensures that every client receives the optimal care regimen for effective and lasting results.
          </Card.Text>

          <Card.Text>
            For those interested in maintaining and enhancing their beauty routine at home, Your Style also provides online courses
            in home cosmetology. These courses are designed to teach you how to care for your skin effectively using professional
            techniques that you can perform yourself. Each course is customized to fit your personal skin care needs and lifestyle, ensuring that you can enjoy professional-level care in the comfort of your own home.
          </Card.Text>

          <Row className="my-4">
            <Col md={4}>
              <img src="./photos/photo4.jpg" alt="Home Cosmetology Courses" className="img-fluid rounded" />
            </Col>
            <Col md={4}>
              <img src="./photos/photo7.jpg" alt="Self-Rejuvenation Techniques" className="img-fluid rounded" />
            </Col>
            <Col md={4}>
              <img src="./photos/photo6.jpg" alt="Natural Rejuvenation" className="img-fluid rounded" />
            </Col>
          </Row>

          <Card.Text>
            In addition to our home care courses, we offer specialized online training for self-rejuvenation. These courses include
            lessons on facial gymnastics, taping, and other innovative techniques that can help reduce the signs of aging. Learn
            to master these modern methods at your own pace with our expert guidance, and discover how to rejuvenate your appearance naturally and effectively.
          </Card.Text>

          <Card.Text>
            At Your Style, we are committed to natural rejuvenation and offer both cosmetic and non-cosmetic methods to help you
            achieve a more youthful and vibrant look without invasive procedures. Our range of services includes both innovative
            and traditional approaches to beauty, all aimed at enhancing your natural features in the most effective way possible.
          </Card.Text>

          <Card.Title as="h2" className="mt-5">Hair Salon</Card.Title>
          <Card.Text>
            Our hair salon offers a comprehensive range of services designed to cater to all your hair care needs. Whether you are looking
            for a fresh new haircut, a vibrant color transformation, or a nourishing treatment to restore the health and shine of your hair,
            our skilled stylists are here to provide expert care. We stay up-to-date with the latest trends and techniques to ensure
            you leave our salon feeling confident and looking your best.
          </Card.Text>

          <Card.Text>
            From classic cuts to the latest trends, our stylists work with you to create a look that reflects your personal style. We
            also offer specialized treatments such as deep conditioning, keratin treatments, and more to enhance the health and beauty
            of your hair.
          </Card.Text>

          <Card.Text>
            Phone Number:
            <ul>
              <li><a href="tel:+1234567890">+420 774 134 693</a></li>
            </ul>
          </Card.Text>

          <Card.Title as="h2" className="mt-5">Nail Salon</Card.Title>
          <Card.Text>
            Our nail salon provides a luxurious and relaxing experience for all your manicure and pedicure needs. Whether you're
            preparing for a special occasion or simply indulging in some self-care, our nail technicians use high-quality products
            and techniques to ensure your nails look flawless. Choose from a wide range of colors and styles, including classic
            French manicures, gel polish, nail art, and more.
          </Card.Text>

          <Card.Text>
            We are committed to providing a clean and safe environment, using sterilized tools and maintaining the highest standards
            of hygiene. Whether you're looking for a quick touch-up or a full set of acrylics, our team is here to help you achieve
            beautiful, healthy nails.
          </Card.Text>

          <Card.Text>
            Phone Number:
            <ul>
              <li><a href="tel:+1234567891">+420 776 684 385</a></li>
            </ul>
          </Card.Text>
        </Card.Body>
      </Card>
      <Location />
    </Container></>
  );
}

export default AboutWeb;

