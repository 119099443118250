import React from 'react';
import { Container, Image, Fade } from 'react-bootstrap';
import { useState, useEffect } from 'react';

function ServicesDetail() {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);
  }, []);

  return (
    <div
      style={{
        position: 'relative',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: '150px 20px',
        color: '#fff',
        textAlign: 'center',
      }}
    >
      {/* Overlay */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      ></div>

      {/* Container with Fade-in Animation */}
      <Container style={{ zIndex: 2, position: 'relative' }}>
        <Fade in={fadeIn} timeout={10000}>
          <div>
            <h1 className="display-4 font-weight-bold">
              Services
            </h1>
            <p className="lead">
              Our pricelist.
            </p>
          </div>
        </Fade>
      </Container>
    </div>
  );
}

export default ServicesDetail;