import React, { useState } from 'react';
import { Container, Row, Col, Card, Image, Button, Modal } from 'react-bootstrap';

const CertificateGallery = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState(null);

  const certificates = [
    {
      id: 1,
      image: '../certificates/dexterAcademy0.jpg',
      title: 'Lymphology - Manual Lymphatic Drainage',
      description: 'Confirmation of absolvation final exams of Lymphology - Manual Lymphatic Drainage.',
      issuedBy: 'Dexter Academy',
      issuedDate: '20.09.2020',
    },
    {
      id: 2,
      image: '../certificates/dexterAcademy2.jpg',
      title: 'Honey massage',
      description: 'Specialized training focusing on the latest skincare technologies and products for professional use.',
      issuedBy: 'Dexter Academy',
      issuedDate: '31.05.2020',
    },
    {
      id: 3,
      image: '../certificates/dexterAcademy3.jpg',
      title: 'Vacuum massage',
      description: 'Comprehensive training on facial anatomy, with a focus on aesthetic treatments and techniques.',
      issuedBy: 'Dexter Academy',
      issuedDate: '12.07.2019',
    },
    {
      id: 4,
      image: '../certificates/dexterAcademy4.jpg',
      title: 'Hot stones - face and decolletage',
      description: 'Hot stones - face and decolletage massage.',
      issuedBy: 'Dexter Academy',
      issuedDate: 'March 2021',
    },
    {
      id: 5,
      image: '../certificates/Dr.Nek.jpg',
      title: 'Ayurveda & Holistic Skincare',
      description: 'Course on using Ayurvedic principles in skincare, focusing on natural and holistic treatments.',
      issuedBy: 'Dr. Nek Holistic School',
      issuedDate: '22.11.2021',
    },
    {
      id: 6,
      image: '../certificates/illustrious.jpg',
      title: 'Illustrious',
      description: 'Course on using Ayurvedic principles in skincare, focusing on natural and holistic treatments.',
      issuedBy: 'illustrious',
      issuedDate: '10.10.2021',
    },
    {
      id: 7,
      image: '../certificates/RoseDeMer.jpg',
      title: 'Ayurveda & Holistic Skincare',
      description: 'Course on using Ayurvedic principles in skincare, focusing on natural and holistic treatments.',
      issuedBy: 'Rose De Mer',
      issuedDate: 'March 2021',
    },
    {
      id: 8,
      image: '../certificates/RoseDeMer2.jpg',
      title: 'rostlinny peeling',
      description: 'Course on using Ayurvedic principles in skincare, focusing on natural and holistic treatments.',
      issuedBy: 'Rose De Mer',
      issuedDate: '09.12.2020',
    },
    {
      id: 9,
      image: '../certificates/StudioParis.jpg',
      title: 'Aqua sonic peel',
      description: 'Course on using Aqua sonic peel, focusing on natural and holistic treatments.',
      issuedBy: 'Studio PARIS',
      issuedDate: '18.08.2020',
    },
  ];

  const handleCertificateClick = (certificate) => {
    setSelectedCertificate(certificate);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const styles = {
    pageContainer: {
      paddingTop: '60px',
      paddingBottom: '60px',
    },
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
    }
  };

  return (
    <Container fluid className="certificate-gallery" style={styles.container}>
      <Row>
        {certificates.map((certificate) => (
          <Col key={certificate.id} xs={6} md={4} lg={3} className="mb-4">
            <Card className="certificate-card shadow-sm border-0 text-center">
              <Image 
                src={certificate.image} 
                alt={certificate.title} 
                fluid 
                rounded 
                className="certificate-image" 
                variant="top"
                style={{ height: '220px', objectFit: 'cover' }}
              />
              <Card.Body>
                <Card.Title className="text-center">{certificate.title}</Card.Title>
                <Card.Text className="text-muted text-center">{certificate.issuedBy}</Card.Text>
                <Button 
                  variant="outline-primary" 
                  onClick={() => handleCertificateClick(certificate)} 
                  block
                >
                  View Details
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedCertificate && selectedCertificate.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCertificate && (
            <div className="text-center">
              <Image 
                src={selectedCertificate.image} 
                alt={selectedCertificate.title} 
                fluid 
                className="mb-3" 
                style={{ borderRadius: '20px' }}
              />
              <p><strong>Issued By:</strong> {selectedCertificate.issuedBy}</p>
              <p><strong>Issued Date:</strong> {selectedCertificate.issuedDate}</p>
              <p>{selectedCertificate.description}</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button 
                  variant="outline-secondary "  onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default CertificateGallery;
