import React from 'react';
import { Container, Row, Col, Card, Image, Button, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import AboutMe from './aboutme.js';
import CertificateGallery from './certification.js';
import MapElement from '../vendor/mapElement.js';
import PhotoCarousel from './photoCarousel.js';
import PhotoElement from './photoElement.js';
import AboutMDetail from '../details/aboutMainDetail.js';



function About() {
  return (
    <>
      <AboutMDetail />
      <Row>
        <Col xs={12} md={6}>
          <PhotoElement />
        </Col>
        <Col xs={12} md={6}>
          <AboutMe />
        </Col>
      </Row>
        {/* Certification Section */}
      <section
        style={{
          padding: '60px 20px',
          backgroundColor: '#f8f9fa',
        }}
      >
        <Container>
          <h2 className="mb-4">My sertification</h2>
          <p className="lead">
            At FaceTherapy, we specialize in restoring and rejuvenating your face naturally. Whether
            you're looking to erase signs of aging, correct facial asymmetry, or recover from
            cosmetic procedures, our certified experts offer personalized care to help you look and
            feel your best.
          </p>
        </Container>
      </section>

    </>
  );
}

export default About;
